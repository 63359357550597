<template>
  <div class="container">
    <div class="inner">
      <h1>Service and Privacy Policy：</h1>
      <p style="padding: 20px 0"></p>
      <p class="a">Detailed description of the services offered:</p>
      <p>1. Influencer discovery and vetting</p>
      <p>2. Campaign management</p>
      <p>3. Performance tracking and analytics</p>
      <p>4. Influencer collaboration tools</p>
      <p>5. Customized influencer marketing strategies</p>
      <p style="padding: 25px 0"></p>
      <p class="a">Influencer Directory:</p>
      <p>
        Searchable database of influencers by category, audience size,
        engagement rate, etc.
      </p>
      <p>
        Profiles with detailed information about each influencer, including
        audience demographics, past campaigns, and contact information.
      </p>
      <p style="padding: 25px 0"></p>
      <p class="a">Campaign Management:</p>
      <p>
        Dashboard for clients to create, manage, and track influencer marketing
        campaigns
      </p>
      <p>Communication tools for collaborating with influencers</p>
      <p>Performance metrics and analytics to measure campaign effectiveness</p>
      <p style="padding: 25px 0"></p>
      <p class="a">Blog/Resource Section:</p>
      <p>
        Articles, case studies, and tips on influencer marketing best practices
      </p>
      <p>Industry news and updates</p>
      <p>FAQs and tutorials for clients</p>
      <p style="padding: 25px 0"></p>
      <p class="a">Contact Us:</p>
      <p>Contact form for inquiries or support requests</p>
      <p>Customer support email and phone number</p>
      <p style="padding: 30px 0"></p>
      <p class="a">Privacy Policy</p>
      <p>1. Introduction</p>
      <p>
        we respects the privacy of our users. This Privacy Policy explains how
        we collect, use, disclose, and safeguard your information when you use
        our website https://influencerdetector.com/ including any other media form,
        media channel, mobile website, or mobile application related or
        connected thereto (collectively, the "Site"). Please read this Privacy
        Policy carefully.
      </p>
      <p style="padding: 15px 0"></p>
      <p>2. Collection of Your Information</p>
      <p>
        We may collect information about you in a variety of ways. The
        information we may collect includes: Personal Data: Personally
        identifiable information, such as your name, email address, and contact
        number that you voluntarily give to us when you register with the Site
        or when you choose to participate in various activities related to the
        Site. Derivative Data: Information our servers automatically collect
        when you access the Site, such as your IP address, browser type, the
        dates and times of your visits, the pages you visit, the time spent on
        those pages, and other statistics.
      </p>
      <p style="padding: 15px 0"></p>
      <p>3. Use of Your Information</p>
      <p></p>
      <p>We may use the information collected in the following ways:</p>
      <p>To operate and maintain the Site;</p>
      <p>To send you marketing communications;</p>
      <p>To respond to inquiries and offer support;</p>
      <p>To personalize your experience on the Site;</p>
      <p>To improve our products and services;</p>
      <p>To track and measure advertising on the Site;</p>
      <p>
        To protect, investigate, and deter against unauthorized or illegal
        activity.
      </p>
      <p style="padding: 15px 0"></p>
      <p>4. Disclosure of Your Information</p>
      <p>
        We may share information we have collected about you in certain
        situations:
      </p>
      <p>
        With our business partners, affiliates, or contractors who provide
        services for us;
      </p>
      <p>With third parties who perform services for us or on our behalf;</p>
      <p>With our professional advisors;</p>
      <p>
        In connection with, or during negotiations of, any merger, sale of
        company assets, financing, or acquisition of all or a portion of our
        business to another company.
      </p>
      <p style="padding: 15px 0"></p>
      <p>5. Security of Your Information</p>
      <p>
        We take steps to protect your information from unauthorized access,
        alteration, disclosure, or destruction using appropriate technical and
        organizational measures.
      </p>
      <p style="padding: 15px 0"></p>
      <p>6. Changes to This Privacy Policy</p>
      <p>
        We reserve the right to update or change our Privacy Policy at any time.
        Your continued use of the Site after we post any modifications to the
        Privacy Policy on this page will constitute your acknowledgment of the
        modifications and your consent to abide and be bound by the modified
        Privacy Policy.
      </p>
      <p style="padding: 15px 0"></p>
      <p>7. Contact Us</p>
      <p>
        If you have questions or comments about this Privacy Policy, please
        contact us at ：support@influencerdetector.com.
      </p>
    </div>
  </div>
</template>

<style lang="less" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  .inner {
    width: 900px;
    padding: 60px 0 60px 0;
  }
  p {
    padding: 4px 0;
    font-size: 15px;
    color: #3d3536;
  }
  .a {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  h1 {
    border-bottom: 1px solid #999;
    padding-bottom: 10px;
  }
}
</style>

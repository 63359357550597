<template>
  <div class="home-container">
    <img src="../../assets/brandinfluencerlink.com.png" alt="" />
    <div class="sub-btn" @click="Subscribe"></div>
    <div class="service" @click="toYs">Service and Privacy Policy</div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const Subscribe = () => {
  const route = router.resolve({
    path: "/subscribe",
  });
  window.open(route.href, "_self");
};
const toYs = () => {
  const route = router.resolve({
    path: "/policy",
  });
  window.open(route.href, "_blank");
};
</script>

<style lang="less" scoped>
.home-container {
  position: relative;
  img {
    width: 100%;
  }
}
.sub-btn {
  position: absolute;
  top: 32.5vw;
  left: 12.78vw;
  width: 10.9375vw;
  height: 2.916vw;
  z-index: 9;
  cursor: pointer;
  transform: translateX(-50%);
}
.service {
  position: absolute;
  bottom: 1.8vw;
  right: 26.958vw;
  font-size: 0.83vw;
  color: red;
  cursor: pointer;
  z-index: 9;
}
</style>

import Home from "./index.vue";
import Subscribe from "./sub.vue";
import Policy from "./policy.vue";
import Icon from "../../assets/lginfluencers.com-logo.png";

export default {
  Home,
  Subscribe,
  Policy,
  title: "Lginfluencers",
  icon: Icon,
};

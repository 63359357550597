<template>
  <div class="container">
    <h1 class="name">{{ "kolspr".toLocaleUpperCase() }}</h1>
    <div class="box">
      <div class="box-item">
        <div class="label">Email Address</div>
        <el-input size="large" v-model="address" placeholder=""></el-input>
      </div>
      <div class="box-item">
        <div class="label">First Name</div>
        <el-input size="large" v-model="firstName" placeholder=""></el-input>
      </div>
      <div class="box-item">
        <div class="label">Last Name</div>
        <el-input size="large" v-model="lastName" placeholder=""></el-input>
      </div>
      <div class="btn">
        <el-button size="large" type="primary" @click="submit"
          >Subscribe</el-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
const address = ref("");
const firstName = ref("");
const lastName = ref("");
const reg = new RegExp(
  "^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$"
);

const submit = () => {
  if (!address.value) return tips("Email Address");
  if (address.value && !reg.test(address.value))
    return ElMessage.error(`Email format error ！`);
  if (!firstName.value) return tips("First Name");
  if (!lastName.value) return tips("Last Name");
  ElMessageBox.alert("Submitted successfully!", "SUCCESS", {
    confirmButtonText: "confirm",
    type: "success",
    center: true,
  }).then(() => {
    address.value = '';
    firstName.value = '';
    lastName.value = '';
  });
};

const tips = (msg) => {
  return ElMessage.error(`${msg} cannot be empty !`);
};
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vw;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  .name {
    margin-top: 100px;
    font-size: 28px;
    color: #000000;
  }
  .box {
    width: 600px;
    padding: 20px;
    font-size: 14px;
    color: #333333;
    border-radius: 6px;
    background: #ffffff;
    margin-top: 30px;
  }
  .box-item {
    margin-bottom: 30px;
    .label {
      font-size: 16px;
      color: #5d5d5d;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
}
</style>

<template>
    <div>
       <h1>404 not found</h1>
    </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
h1 {
    text-align: center;
    padding-top: 200px;
}
</style>
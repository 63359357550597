import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/404.vue";
import Home from "../views/index.vue";

import Influencerdetector from "../views/influencerdetector";
import Influenceselect from "../views/influenceselect";
import Koldream from "../views/koldream";
import Mcnnmarketing from "../views/mcnnmarketing";

import Agencykol from "../views/agencykol";
import Kolspr from "../views/kolspr";
import Cooperationkol from "../views/cooperationkol";
import Lginfluencers from "../views/lginfluencers";
import Buzzmakerhub from "../views/buzzmakerhub";
import Brandinfluencerlink from "../views/brandinfluencerlink";

export const ComponentMap = {
  "influencerdetector.com": Influencerdetector,
  "koldream.com": Koldream,
  "mcnnmarketing.com": Mcnnmarketing,
  "influenceselect.com": Influenceselect,
  "agencykol.com": Agencykol,
  "kolspr.com": Kolspr,
  "cooperationkol.com": Cooperationkol,
  "lginfluencers.com": Lginfluencers,
  'buzzmakerhub.com': Buzzmakerhub,
  "brandinfluencerlink.com": Brandinfluencerlink
};

const linkDom = document.querySelector('link[rel="icon"]');

console.log(process.env.NODE_ENV);

function getComponentByHref(name) {
  const urlFlag =
    process.env.NODE_ENV === "development"
      ? location.hash.replace("#", "")
      : location.hostname;
  console.log(urlFlag);
  if (urlFlag && ComponentMap.hasOwnProperty(urlFlag)) {
    document.title = ComponentMap[urlFlag]?.title || "email-official";
    if (linkDom) linkDom.href = ComponentMap[urlFlag]?.icon || "";
    return ComponentMap[urlFlag][name];
  } else {
    return process.env.NODE_ENV === "development" ? Home : NotFound;
  }
}

const routes = [
  {
    path: "/",
    name: "home",
    component: getComponentByHref("Home"),
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: getComponentByHref("Subscribe"),
  },
  {
    path: "/policy",
    name: "policy",
    component: getComponentByHref("Policy"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
